import React from 'react'
import {navigate} from "gatsby"
import '../../node_modules/leaflet/dist/leaflet.css'
import {CircleMarker, Map, TileLayer, Tooltip} from 'react-leaflet'

const accessToken = 'pk.eyJ1Ijoicm9lbHZhbmRlcnBhYWwiLCJhIjoiY2p0bHE2M2phMDg1MjN6bGlqYjE0N2ZneSJ9.NhNA-D98SylLYCQREK9sEg'

const MapComponent = ({location, zoom, points, height = 500}) => {
  if (typeof window !== 'undefined') {
    return (
      <div style={{height}}>
        <Map
          center={[location.latitude, location.longitude]}
          zoom={zoom}
          scrollWheelZoom={false}
          style={{height}}
        >
          <TileLayer
            id='mapbox://styles/mapbox/light-v9'
            attribution='© <a href="https://www.mapbox.com/about/maps/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> <strong><a href="https://www.mapbox.com/map-feedback/" target="_blank" rel="noopener">Improve this map</a></strong>'
            url={'https://api.mapbox.com/styles/v1/roelvanderpaal/cjtlqgq271qg91fs063klikrm/tiles/256/{z}/{x}/{y}@2x?access_token={accessToken}'}
            accessToken={accessToken}
          />
          {points
            .map(p => <CircleMarker key={p.name} center={[p.location.latitude, p.location.longitude]} radius={3}
                                    color='black' onClick={() => {
              if (p.slug) {
                navigate('/cities/' + p.slug)
              }
            }}>
              <Tooltip>{p.name}</Tooltip>
            </CircleMarker>)}
        </Map>
      </div>
    )
  }
  return null
}

export default MapComponent
