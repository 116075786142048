import React from "react"
import Layout from "../components/layout"
import Img from "gatsby-image"
import {graphql, Link} from "gatsby";
import Map from "../components/map";
import SEO from "../components/seo";

const average = arr => arr.reduce((p, c) => p + c, 0) / arr.length

export default ({data: {datoCmsCitygroup: c}}) => {
  return (
    <Layout>
      <SEO title={`Continent ${c.title}`} seo={c.seoMetaTags} image={c.image.url}/>
      <div className='relative' key={c.id}>
        <Img className='align-top city-image' fluid={c.image.fluid}/>
        <span className="city-title absolute font-title text-white text-5xl">{c.title}</span>
      </div>
      <Map location={{
        latitude: average(c.cities.map(c => c.location.latitude)),
        longitude: average(c.cities.map(c => c.location.longitude))
      }}
           zoom={3}
           points={c.cities}
      />
      <div className="flex flex-wrap justify-center w-full">
        {c.cities.map(city => <Link className='relative flex-grow m-1' to={'/cities/' + city.slug + '/'}
                                    key={city.slug}>
            <Img className='darken' style={{minWidth: 200}} fluid={city.imagelist.fluid}/>
            <span
              className='z-50 absolute uppercase text-white city-title font-title font-light text-xl'>{city.name}</span>
          </Link>
        )}
      </div>
    </Layout>
  )
}

export const query = graphql`
    query GetCityGroup($slug:String)     {
        datoCmsCitygroup(slug: {eq: $slug}) {
            seoMetaTags {
                ...GatsbyDatoCmsSeoMetaTags
            }
            title
            image {
                fixed(width: 1000, height: 300, imgixParams: {fit:"crop" }) {
                    ...GatsbyDatoCmsFixed
                }
                fluid(maxWidth: 1500, imgixParams: {fit: "crop", w:"1000", h:"300"}) {
                    ...GatsbyDatoCmsFluid
                }
                url
            }
            cities {
                name
                slug
                location{
                    longitude
                    latitude
                }
                imagelist {
                    fluid(maxWidth: 300, imgixParams: { fit: "crop", w:"300", h:"300" }) {
                        ...GatsbyDatoCmsFluid
                    }
                }
            }
        }
    }

`
